<template>
  <div class="backMenu">
    <div class="">
      <el-card class="box-card">
        <div class="MenuTop">
          <!-- <div class="hint-title">按钮名称：</div> -->
          <div>
            <el-input v-model="MenuApi.keyWords" placeholder="请输入内容">
              <template slot="prepend">按钮名称：</template>
            </el-input>
          </div>
          <div class="btn">
            <el-button type="primary" @click="toSearch">查 询</el-button>
          </div>
          <div class="btn">
            <el-button type="primary" @click="openAdd"
              >新建当前页按钮</el-button
            >
          </div>
        </div>
        <div class="MenuList scorr-roll">
          <el-table :data="BtnList" border style="width: 100%">
            <el-table-column
              prop="date"
              label="编号"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="按钮名称"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="接口权限key"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.api_path }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="按钮key"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.path }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              width="200"
              align="center"
              v-if="BtnJurisdiction('/Auth/handleBotton')"
            >
              <template slot-scope="{ row }">
                <div class="rootBtn">
                  <button
                    v-if="BtnJurisdiction('/Auth/handleBotton')"
                    @click="toRedact(row)"
                  >
                    编辑
                  </button>
                  <button @click="todel(row)">删除</button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="total,prev, pager, next, jumper"
            :total="BtnList.length"
          >
          </el-pagination>
        </div>
        <!-- 新增和编辑弹框 -->
        <div>
          <el-dialog title="按钮" :visible.sync="isAdd" width="700px">
            <div class="AddMenu scorr-roll">
              <div class="AddMenu-1-box">
                <div>
                  <el-form label-width="120px">
                    <el-form-item label="按钮名称:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.name"
                          placeholder="请输入按钮名称"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="按钮权限key:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.path"
                          placeholder="请输入按钮权限key"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="接口地址key" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.api_path"
                          placeholder="请输入后台接口权限key"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isAdd = false">取 消</el-button>
              <el-button type="primary" @click="AddBtn">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power"); //vuex公共库
export default {
  data() {
    return {
      isAdd: false,
      BtnList: [],
      MenuApi: {
        pid: this.$route.query.id,
        keyWords: "",
        page: 1,
      }, //请求借口的参数
      addData: {
        pid: this.$route.query.id, //父级id
        name: "", //名称
        type: 1,
        api_path: "", //接口地址
        path: "", //按钮key
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getBotton();
    } else {
      this.$message({
        message: "请联系后台管理人员",
        type: "warning",
      });
      setTimeout(() => {
        this.$router.push({ path: "/backMenuList" });
      }, 500);
    }
  },
  methods: {
    ...mapActions(["getBottonList", "postAddBotton", "postHandleBotton"]),
    // 删除按钮权限
    async todel(row) {
      //console.log(row);
      let data = await this.postHandleBotton({
        id: row.id,
        act: "del",
        name: row.name,
      });
      if (data.code == 200) {
        this.getBotton();
        this.isAdd = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 新建按钮
    async AddBtn() {
      if (
        this.addData.name == "" ||
        this.addData.path == "" ||
        this.addData.api_path == ""
      ) {
        this.$message({
          message: "请将带星号的填写完整",
          type: "warning",
        });
        return;
      }
      let data = null;
      if (this.addData.id) {
        data = await this.postHandleBotton(this.addData);
      } else {
        data = await this.postAddBotton(this.addData);
        //console.log(data);
      }
      if (data.code == 200) {
        this.getBotton();
        this.isAdd = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 打开新建按钮弹框
    openAdd() {
      this.addData = {
        pid: this.$route.query.id, //父级id
        name: "", //名称
        type: 1,
        api_path: "", //接口地址
        path: "", //按钮key
      };
      this.isAdd = true;
    },
    // 编辑
    toRedact(data) {
      this.addData = data;
      this.isAdd = true;
    },
    // 搜索
    toSearch() {
      this.MenuApi.page = 1;
      this.getBotton();
    },
    // 获取子级按钮列表
    async getBotton() {
      let data = await this.getBottonList(this.MenuApi);
      if (data.code == 200) {
        this.BtnList = data.data;
      }
    },
    // 翻页
    handleCurrentChange() {},
    // 按钮权限
    BtnJurisdiction,
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    .hint-title {
      font-size: 15px;
      font-weight: bold;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06B7AE;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 300px;
      }
    }
  }
}
</style>